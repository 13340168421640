var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import QRCode from "react-qr-code";
import { Box, Typography, useTheme } from "@mui/material";
import Logo from "@/components/Logo";
var Landing = function (_a) {
    var matchId = _a.matchId;
    var theme = useTheme();
    var link = "http://" + window.location.host + "/display/" + matchId;
    return (_jsxs(Box, __assign({ sx: {
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.palette.background.default,
            fontFamily: "korolev",
        } }, { children: [_jsx(Box, __assign({ sx: {
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#FFFFFF",
                } }, { children: _jsx(Logo, { width: "60%", height: "auto" }) })), _jsxs(Box, __assign({ sx: {
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8BC53F",
                    color: "#FFFFFF", // White text for contrast
                } }, { children: [_jsx("div", __assign({ style: {
                            height: "auto",
                            margin: "0 auto",
                            maxWidth: "50%",
                            width: "100%",
                        } }, { children: _jsx(QRCode, { bgColor: "#8BC53F", fgColor: "#ffffff", size: 256, style: { height: "auto", maxWidth: "100%", width: "100%" }, value: link }) })), _jsx(Typography
                    // variant="h1"
                    , __assign({ 
                        // variant="h1"
                        sx: {
                            marginTop: 4,
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "5vw",
                            fontFamily: "korolev",
                        } }, { children: "Scan to Play!" }))] }))] })));
};
export default Landing;
