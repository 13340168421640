var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import QRCode from "react-qr-code";
import Logo from "@/components/Logo";
import { MatchFormat } from "shared/models/matchModels";
var MatchLobby = function (_a) {
    var data = _a.data, matchId = _a.matchId;
    var link = "http://" + window.location.host + "/feedback";
    return (_jsxs(Box, __assign({ sx: {
            height: "100vh",
            display: "flex",
            flexDirection: "row",
        } }, { children: [_jsxs(Box, __assign({ sx: {
                    flex: 1,
                    backgroundColor: "#8BC53F",
                    color: "#FFFFFF",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5vh",
                } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "2vw",
                            marginBottom: "4vh",
                            fontFamily: "korolev",
                        } }, { children: "MSP app is currently in beta testing and will soon be available on the App Store and Playstore" })), _jsxs(Box, __assign({ sx: {
                            textAlign: "center",
                            marginBottom: "4vh",
                        } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                                    fontSize: "1.5vw",
                                    marginBottom: "2vh",
                                    fontFamily: "korolev",
                                } }, { children: "Please scan QR code for any feedback and suggestions" })), _jsx("div", __assign({ style: {
                                    height: "auto",
                                    margin: "0 auto",
                                    maxWidth: "50%",
                                    width: "100%",
                                } }, { children: _jsx(QRCode, { bgColor: "#8BC53F", fgColor: "#ffffff", size: 256, style: { height: "auto", maxWidth: "100%", width: "100%" }, value: link }) }))] }))] })), _jsxs(Box, __assign({ sx: {
                    flex: 4,
                    backgroundColor: "#FFFFFF",
                    padding: "5vh",
                    display: "flex",
                    flexDirection: "column",
                } }, { children: [_jsx(Box, __assign({ sx: { textAlign: "center", marginBottom: "4vh" } }, { children: _jsx(Logo, { width: "20%", height: "auto" }) })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: {
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "stretch",
                        } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4vh", // Space between the two stacked cards
                                } }, { children: [_jsxs(Paper, __assign({ sx: {
                                            backgroundColor: "#8BC53F",
                                            color: "#FFFFFF",
                                            padding: "3vh",
                                            borderRadius: "1vw",
                                            textAlign: "left",
                                            fontSize: "1.5vw",
                                            flex: 1, // Ensure this card takes up equal space
                                        } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                    fontWeight: "bold",
                                                    fontSize: "2.5vw",
                                                    marginBottom: "5vh",
                                                    fontFamily: "korolev",
                                                } }, { children: data.sessionName })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2vw", fontFamily: "korolev" } }, { children: ["Number of Players: ", data.players] })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2vw", fontFamily: "korolev" } }, { children: ["Match Format: ", data.format] })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2vw", fontFamily: "korolev" } }, { children: [data.format === MatchFormat.TIMED
                                                        ? "Time Per Round:"
                                                        : "Points to Win:", " ", data.limit, " ", data.format === MatchFormat.TIMED ? "minutes" : "points"] }))] })), _jsx(Paper, __assign({ sx: {
                                            padding: "3vh",
                                            borderRadius: "1vw",
                                            textAlign: "left",
                                            fontSize: "1.5vw",
                                            borderColor: "#8BC53F",
                                            borderWidth: "0.3vw",
                                            borderStyle: "solid",
                                            flex: 1, // Ensure this card takes up equal space
                                        } }, { children: _jsxs(Typography, __assign({ variant: "h5", sx: {
                                                marginTop: "2vh",
                                                lineHeight: "5vh",
                                                fontSize: "2vw",
                                                fontFamily: "korolev",
                                            } }, { children: [data.format === MatchFormat.POINTS
                                                    ? "- End round at first team to ".concat(data.limit, " points")
                                                    : "- Switch after every ".concat(data.limit, " minutes"), _jsx("br", {}), "- 2 serves per person (alternate)", _jsx("br", {}), "- Points increment by 1", _jsx("br", {}), "- Submit score after every round"] })) }))] })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                } }, { children: _jsxs(Paper, __assign({ sx: {
                                        padding: "3vh",
                                        borderRadius: "1vw",
                                        textAlign: "left",
                                        fontSize: "1.5vw",
                                        flex: 1,
                                        overflow: "hidden",
                                        borderColor: "#8BC53F",
                                        borderWidth: "0.3vw",
                                        borderStyle: "solid",
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                fontWeight: "bold",
                                                fontSize: "2vw",
                                                marginBottom: "2vh",
                                                fontFamily: "korolev",
                                            } }, { children: "Players" })), _jsx(Box, __assign({ sx: {
                                                display: "grid",
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                                gap: "1.5vh",
                                                overflowY: "auto",
                                                padding: "1vh",
                                            } }, { children: data.playerList.map(function (player, index) { return (_jsxs(Typography, __assign({ variant: "h5", sx: {
                                                    marginBottom: "1vh",
                                                    fontSize: "1.5vw",
                                                    textAlign: "left",
                                                    fontFamily: "korolev",
                                                } }, { children: [index + 1, ". ", player.name] }), index)); }) }))] })) }))] }))] }))] })));
};
export default MatchLobby;
