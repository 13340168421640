import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../utils/firestore";
var matchesCollection = collection(db, "matches");
var displayCollection = collection(db, "display");
export var listenToMatch = function (matchId, callback) {
    var matchDoc = doc(matchesCollection, matchId);
    var unsubscribe = onSnapshot(matchDoc, function (snapshot) {
        if (snapshot.exists()) {
            callback(snapshot.data());
        }
        else {
            callback(null);
        }
    });
    return unsubscribe;
};
export var listenToDisplayChange = function (displayId, callback) {
    var displayDoc = doc(displayCollection, displayId);
    var unsubscribe = onSnapshot(displayDoc, function (snapshot) {
        if (snapshot.exists()) {
            callback(snapshot.data());
        }
        else {
            callback(null);
        }
    });
    return unsubscribe;
};
