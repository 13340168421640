import { createTheme } from "@mui/material/styles";
var lightThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "#1976d2",
        },
        secondary: {
            main: "#dc004e",
        },
        background: {
            default: "#f5f5f5",
            paper: "#ffffff",
        },
        text: {
            primary: "#00000",
            secondary: "#1976d2",
            disabled: "#9e9e9e",
        },
    },
};
var darkThemeOptions = {
    palette: {
        mode: "dark",
        primary: {
            main: "#2d383c",
        },
        secondary: {
            main: "#f48fb1",
        },
        background: {
            default: "#303030",
            paper: "#424242",
        },
        text: {
            primary: "#ffffff",
            secondary: "#1976d2",
            disabled: "#9e9e9e",
        },
    },
};
export var medalColors = {
    gold: { main: "#FFD700" },
    silver: { main: "#C0C0C0" },
    bronze: { main: "#CD7F32" },
};
export var lightTheme = createTheme(lightThemeOptions);
export var darkTheme = createTheme(darkThemeOptions);
