var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var randomAlphaNumeric = function (length) {
    var s = "";
    Array.from({ length: length }).some(function () {
        s += Math.random().toString(36).slice(2);
        return s.length >= length;
    });
    return s.slice(0, length);
};
export var shuffleArray = function (array2) {
    var _a;
    for (var i = array2.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        _a = [array2[j], array2[i]], array2[i] = _a[0], array2[j] = _a[1];
    }
    return __spreadArray([], array2, true);
};
