import { useState, useEffect } from "react";
import useSound from "use-sound";
import tickSound from "../assets/countdown.mp3";
import endSound from "../assets/end.mp3";
var useCountdown = function (initialLimit, initialState) {
    var _a = useState(3), initialCountdown = _a[0], setInitialCountdown = _a[1];
    var _b = useState(initialState), showTimer = _b[0], setShowTimer = _b[1];
    var _c = useState(initialLimit * 60), countdown = _c[0], setCountdown = _c[1];
    var _d = useState(false), timerFinished = _d[0], setTimerFinished = _d[1];
    var playTick = useSound(tickSound)[0];
    var playEnd = useSound(endSound)[0];
    useEffect(function () {
        setTimerFinished(false);
        if (initialState) {
            setInitialCountdown(3);
            setShowTimer(true);
            setCountdown(initialLimit * 60);
        }
        else {
            setShowTimer(false);
        }
    }, [initialState, initialLimit]);
    useEffect(function () {
        if (showTimer) {
            if (initialCountdown > 0) {
                var initialInterval_1 = setInterval(function () {
                    setInitialCountdown(function (prevCountdown) {
                        if (prevCountdown <= 1) {
                            clearInterval(initialInterval_1);
                            return 0;
                        }
                        if (prevCountdown === 3) {
                            playTick();
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
                return function () { return clearInterval(initialInterval_1); };
            }
            else {
                var interval_1 = setInterval(function () {
                    setCountdown(function (prevCountdown) {
                        if (prevCountdown <= 1) {
                            clearInterval(interval_1);
                            playEnd();
                            setInitialCountdown(3);
                            setShowTimer(false);
                            setTimerFinished(true);
                            return 0;
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
                return function () { return clearInterval(interval_1); };
            }
        }
    }, [showTimer, initialCountdown, playTick, playEnd]);
    var formatTime = function (seconds) {
        var minutes = Math.floor(seconds / 60);
        var remainingSeconds = seconds % 60;
        return "".concat(minutes, ":").concat(remainingSeconds < 10 ? "0" : "").concat(remainingSeconds);
    };
    return {
        initialCountdown: initialCountdown,
        showTimer: showTimer,
        countdown: countdown,
        timerFinished: timerFinished,
        formatTime: formatTime,
    };
};
export default useCountdown;
