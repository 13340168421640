var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Typography } from "@mui/material";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import { keyframes } from "@mui/system";
var spin = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n"], ["\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n"])));
var Loading = function () {
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }, { children: [_jsx(SportsBaseballIcon, { sx: {
                    fontSize: 60,
                    color: "primary.main",
                    animation: "".concat(spin, " 1s linear infinite"),
                } }), _jsx(Typography, __assign({ variant: "h6", mt: 2 }, { children: "Loading..." }))] })));
};
export default Loading;
var templateObject_1;
