import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Loading from "@/components/Loading";
import { listenToMatch } from "@/dao/match";
import { MatchState } from "shared/models/matchModels";
import Info from "./info";
import PostMatch from "./postmatch";
import MatchLobby from "./matchlobby";
var Match = function () {
    var matchId = useParams().matchId;
    var _a = useState(null), matchData = _a[0], setMatchData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        if (matchId) {
            var unsubscribe_1 = listenToMatch(matchId, function (data) {
                setMatchData(data);
                setLoading(false);
            });
            return function () {
                unsubscribe_1();
            };
        }
    }, [matchId]);
    if (!matchId)
        return _jsx(Navigate, { to: "/display", replace: true });
    if (loading)
        return _jsx(Loading, {});
    if (matchData) {
        switch (matchData.state) {
            case MatchState.NEW:
                return _jsx(MatchLobby, { data: matchData, matchId: matchId });
            case MatchState.READY:
            case MatchState.IN_PROGRESS:
                return _jsx(Info, { data: matchData });
            case MatchState.FINISHED:
                return _jsx(PostMatch, { data: matchData });
        }
    }
    else {
        return _jsx(Navigate, { to: "/display", replace: true });
    }
};
export default Match;
