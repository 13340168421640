var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, Typography, TableBody, } from "@mui/material";
import { useNavigate } from "react-router-dom";
export var ScoreTable = function (props) {
    var playersScore = props.playersScore, playerDatas = props.playerDatas;
    var navigate = useNavigate();
    return (_jsx(TableContainer, __assign({ component: Paper, sx: { flex: 1, marginRight: 2, height: "85vh" } }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ align: "center" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "PLAYERS" })) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "POINTS" })) })), props.extended && (_jsxs(_Fragment, { children: [_jsx(TableCell, __assign({ align: "center" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "AVE" })) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "WINS" })) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "LOSS" })) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "DRAW" })) }))] }))] }) }), _jsx(TableBody, { children: playersScore.map(function (player, index) {
                        var netScore = player.playerScore - player.pointsConceded;
                        return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ align: "center", onClick: function () {
                                        if (playerDatas[player.playerIndex].uid)
                                            navigate("/user/".concat(playerDatas[player.playerIndex].uid));
                                    } }, { children: playerDatas[player.playerIndex].name })), _jsxs(TableCell, __assign({ align: "center" }, { children: [player.playerScore, " ", props.extended && (_jsxs("span", __assign({ style: { color: netScore >= 0 ? "green" : "red" } }, { children: ["(", netScore >= 0 ? "+" : "", netScore, ")"] })))] })), props.extended && (_jsxs(_Fragment, { children: [_jsx(TableCell, __assign({ align: "center" }, { children: Math.round((player.playerScore / props.rounds) * 100) /
                                                100 })), _jsx(TableCell, __assign({ align: "center" }, { children: player.gamesWon })), _jsx(TableCell, __assign({ align: "center" }, { children: player.gamesLost })), _jsx(TableCell, __assign({ align: "center" }, { children: player.gamesDraw }))] }))] }, index));
                    }) })] }) })));
};
