var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import QRCode from "react-qr-code";
var QRCodeDisplay = function () {
    return (_jsx(Paper, __assign({ sx: { height: "100%", my: 0 } }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, md: 12, container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 3, padding: 1 }, { children: _jsx(Box, __assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(QRCode, { value: window.location.href, size: 150 }) })) })), _jsxs(Grid, __assign({ item: true, xs: 12, md: 9 }, { children: [_jsx(Typography, __assign({ variant: "h6", component: "h2", align: "center" }, { children: "Scan QR for this page" })), _jsx(Typography, __assign({ variant: "h6", component: "h2", align: "center" }, { children: "Scan to view and save data of this match" })), _jsx(Typography, __assign({ sx: { fontStyle: "italic", fontSize: 14 }, component: "h6", align: "center" }, { children: window.location.href }))] }))] })) })));
};
export default QRCodeDisplay;
