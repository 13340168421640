var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Paper, Typography } from "@mui/material";
var StatisticsCard = function (_a) {
    var title = _a.title, value = _a.value, description = _a.description;
    return (_jsxs(Paper, __assign({ sx: { height: "100%", my: 1 } }, { children: [_jsx(Typography, __assign({ variant: "h6", align: "center" }, { children: title })), _jsx(Typography, __assign({ variant: "h3", align: "center" }, { children: value })), _jsx(Typography, __assign({ sx: { fontStyle: "italic", fontSize: 18 }, component: "h6", align: "center" }, { children: description }))] })));
};
export default StatisticsCard;
