var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import Display from "./pages/display/Display";
import { randomAlphaNumeric } from "shared/utils/shared";
import { lightTheme, darkTheme } from "./theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import Match from "./pages/display/Match";
var root = createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(BrowserRouter, { children: _jsx(ThemeProvider, __assign({ theme: false ? darkTheme : lightTheme }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/display", element: _jsx(Navigate, { to: "/display/".concat(randomAlphaNumeric(10)), replace: true }) }), _jsx(Route, { path: "/display/:displayId", element: _jsx(Display, {}) }), _jsx(Route, { path: "/match/:matchId", element: _jsx(Match, {}) }), _jsx(Route, { path: "/*", element: _jsx(Navigate, { to: "/display", replace: true }) })] }) })) }) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
